import React from "react";
import parse from "html-react-parser";
import PressReleaseCard from "../PressReleaseCard";
import pressSeven from "../../assets/images/press-seven.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getPressReleaseData } from "../../services/dataServices";
import Image from "../../components/Image";

function MediaPressRelease({ heading = "PRESS RELEASES", manageMedia,slugOfMedia }) {
  let allPressRelease = useQuery("getAllPressRelease", getPressReleaseData);

  /*******************************MEDIA PRESSREALEASES START********************************* */

  let pressReleasesData = manageMedia?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "PRESSRELEASES"
  );
 
  const pressRealseIcon =
    pressReleasesData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "ICON"
    )[0]?.data ?? null;

  const pressRealseHeading =
    pressReleasesData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "HEADING"
    )[0]?.data ?? null;

  const pressRealseDescription =
    pressReleasesData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "DESCRIPTION"
    )[0]?.data ?? "";

  const truncateText = (text = "", length) => {
    return text?.length > +length ? text.slice(0, +length) + "..." : text;
  };

  /*******************************MEDIA PRESSREALEASES END********************************* */
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="heading-icon mb-xl-4 mb-2 pt-2 pb-2">
              {/* <img src={pressRealseIcon} title="" alt="" /> */}
              <Image src={pressRealseIcon} title="" alt="" />
            </div>
            <div className="small-heading">
              {/* <span className='text-uppercase'>{smallHeading}</span> */}
            </div>
            <h1 className="text-uppercase georgia heading-space mt-xl-4 mt-2">
              {pressRealseHeading}
            </h1>
            <div className="media-para m-auto pt-3 text-center">
              <p>{parse(pressRealseDescription)}</p>
            </div>
          </div>
          <div className="press-releases-cards mt-lg-5 mt-4">
            <div className="row gy-5">
              {allPressRelease?.data?.data?.map((press) => (
                <div className="col-lg-6" key={press.website_press_release_id}>
                  <PressReleaseCard
                  imgAltTag={press.image_alt_tag}
                    cardImage={press.image_path ? press.image_path : pressSeven}
                    cardTitle={parse(truncateText(press.title, 44))}
                    cardDate={press.formattedDateTime}
                    cardDescription={parse(
                      truncateText(press.description, 120)
                    )}
                    cardLink={"Learn More"}
                    cardUrl={`/press-release-details/${press.website_press_release_id}`}
                    tags={press.tags}
                    keywords={press.keywords}
                    slug={`/${slugOfMedia}/${press.slug}`}
                    metaDescription={press.meta_description}
                    title={press.meta_title}
                  />
                </div>
              ))}

              <div className="show-more d-flex justify-content-center d-none">
                <Link
                  to="/"
                  className="w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control"
                >
                  Show More{" "}
                  <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MediaPressRelease;
