import React from "react";
import parse from "html-react-parser";
import CollateralMenuCards from "./CollateralMenuCards";
import pdfIcon from "../../assets/images/pdf.svg";
import downloadNow from "../../assets/images/download.svg";
import CollateralCards from "./CollateralCards";
import Image from "../../components/Image";
import { getAllCollateral } from "../../services/dataServices";
import { useQuery } from "react-query";
function MediaCollaterals({
  smallHeading = "COLLATERALS",
  heading = "OUR COLLATERALS",
  manageMedia,
}) {
  const allCollateral = useQuery("getAllCollateral", getAllCollateral);
  const dataCollateral = allCollateral?.data?.data;
 
  const collateralTitle = dataCollateral?.map((item) => item);

  let collateralData = manageMedia?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "COLLATERALS"
  );
  const collateralIcon =
    collateralData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "ICON"
    )[0]?.data ?? null;

  const collateralHeading =
    collateralData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "HEADING"
    )[0]?.data ?? null;

  const collateralDescription =
    collateralData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "DESCRIPTION"
    )[0]?.data ?? "";

  const planTitle = "Kanifushi Menu";
  const menuTitle = "Kanifushi Plans";

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="heading-icon mb-xl-4 mb-2 pt-2 pb-2">
              {/* <img src={collateralIcon} title='' alt='' /> */}
              <Image src={collateralIcon} title="" alt="" />
            </div>
            <h1 className="text-uppercase georgia heading-space mt-xl-4 mt-2">
              {collateralHeading}
            </h1>
            <div className="media-para m-auto pt-3 text-center">
              <p>{parse(collateralDescription)}</p>
            </div>
          </div>

          <div className="collaterals-cards mt-lg-5 mt-4">
            <div className="row gy-4">
              <div className="col-12">
                {/* <h4 className='text-uppercase georgia letter-spacing-2'>{menuTitle}</h4> */}

                {dataCollateral?.map((item) => (
                  <div className="mb-5" key={item?.head_id}>
                    <h4 className="text-uppercase georgia letter-spacing-2 mb-3 ">
                      {item?.collateral_name}{" "}
                    </h4>

                    <div className="row gy-4">
                      {item?.data?.map((collateralItem) => (
                        <div className="col-lg-6">
                          <CollateralCards
                            key={collateralItem.id}
                            collateralTitle={collateralItem.title}
                            collateralPara={collateralItem.description}
                            docTypeIcon={pdfIcon}
                            docDownloadIcon={downloadNow}
                            downloadCollateral={collateralItem.pdf_url}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              {/* {collateralMenuVaru.map((menuCollateralCard) =>(
                    <div className='col-lg-6'>
                      <CollateralMenuCards
                        key={menuCollateralCard}
                        menuDocTypeIcon={menuCollateralCard.menuDocTypeIcon}
                        menuCollateralTitle={menuCollateralCard.menuCollateralTitle}
                        menuCollateralPara={menuCollateralCard.menuCollateralPara}
                        menuDdocDownloadIcon={menuCollateralCard.menuDdocDownloadIcon}
                        menuDownloadCollateral={menuCollateralCard.menuDownloadCollateral}
                      />
                    </div>
                  ))} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MediaCollaterals;
