import React, { useState } from "react";
import { Link } from "react-router-dom";
import ExploreVillaSlides from "./ExploreVillaSlides";
import { Button } from "react-bootstrap";
import Modals from "./Modals";
import Image from "./Image";
function VillasTabItem({
  villaName,
  villaSlogan,
  villaPara,
  adultCount,
  villaBed,
  villaUserIcon,
  villaBedIcon,
  villaArea,
  villaAreaIcon,
  viewDetailsLink,
  detailText,
  bookNowLink,
  bookNowText,
  images,
  thumbimages,
}) {
  const beachSliderLargeSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const beachSliderThumbSettings = {
    arrows: true,
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 6000,
    slidesToScroll: 1,
    centerMode: false,
    slidesToShow: 4,
    // slidesToShow: Math.min(thumbimages?.length, 4),
    // slidesToShow: Math.min(thumbimages?.length, 4),

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [menuItem, setMenuItem] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div className="explore-villas-tab d-flex flex-wrap h-100">
        <div className="villa-details">
          <div className="villas-name">
            <Link className="text-decoration-none h4" to={viewDetailsLink}>
              <h4 className="georgia mb-3 text-capitalize">{villaName}</h4>
            </Link>
            <span className="fw-bold villa-slogan georgia">
              <i>{villaSlogan}</i>
            </span>
            <div className="villa-para">
              <p>{villaPara}</p>
            </div>
            <ul className="list-unstyled m-0">
              <li>
                <span className="info-icon">
                  {/* <img
                    src={villaUserIcon}
                    alt=''
                    title=''
                  /> */}

                  <img className="" src={villaUserIcon} alt={""} />
                </span>{" "}
                <span className="info-text">{adultCount}</span>
              </li>
              <li>
                <span className="info-icon">
                  {/* <img src={villaBedIcon} alt="" title="" /> */}
                  <img className="" src={villaBedIcon} alt={""} />
                </span>{" "}
                <span className="info-text">{villaBed}</span>
              </li>
              <li>
                <span className="info-icon">
                  {/* <img src={villaAreaIcon} alt="" title="" /> */}
                  <img className="" src={villaAreaIcon} alt={""} />
                </span>{" "}
                <span className="info-text">{villaArea}</span>
              </li>
            </ul>
            <div className="villa-actions mt-xl-5 mt-4">
              <Link
                className="btn primary theme-btn me-3 dark-btn fw-medium text-white text-uppercase border-theme"
                to={viewDetailsLink}
              >
                {detailText}
              </Link>
              {/* book_now_villas_ga4 this class is for triggring the ga4 event */}
              {/* <Button
                onClick={handleShow}
                className="btn primary theme-btn fw-medium text-white text-uppercase border-theme book_now_villas_ga4"
              >
                {bookNowText}
              </Button> */}
              <Link
                // onClick={handleShow}
                to={'https://reservations.atmosphere-kanifushi.com/book/dates-of-stay'}
                target="_blank"
                className="btn primary theme-btn fw-medium text-white text-uppercase border-theme book_now_villas_ga4"
              >
                {bookNowText}
              </Link>
              <Modals show={show} handleClose={handleClose} />
            </div>
          </div>
        </div>
        {/* /view details */}

        <div className="villas-slides">
          <ExploreVillaSlides
            images={images}
            thumbimages={thumbimages}
            beachSliderLargeSettings={beachSliderLargeSettings}
            beachSliderThumbSettings={beachSliderThumbSettings}
          />
        </div>
        {/* villas slides */}
      </div>
    </>
  );
}

export default VillasTabItem;
