import React from "react";
import { Link } from "react-router-dom";
import ExplorePlanTabs from "./ExplorePlanTabs";

function KanifushPlan({
  planExploreHeading,
  planExploreButton,
  planExploreImage,
  PlanData,
  planSlug,
}) {
  const exploreHeading = "THE KANIFUSHI PLAN";
  return (
    <>
      <section>
        <div className="explore-kanifushi-plan home-explore-plan">
          <div className="container-fluid custom-container">
            <div className="row">
              <div className="hotel-heading-wrap mb-lg-5 mb-4 d-flex align-items-center justify-content-between">
                <h2 className="text-uppercase georgia heading-space mb-0">
                  {planExploreHeading}
                </h2>
                <Link
                  to={`/${planSlug}`}
                  className="georgia btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme"
                >
                  {planExploreButton}
                </Link>
              </div>
            </div>

            <div className="d-flex flex-wrap">
              <ExplorePlanTabs PlanData={PlanData} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default KanifushPlan;
