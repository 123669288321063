import React, { useContext, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getPageData, getPageData1 } from "../../services/dataServices";
import parse from "html-react-parser";
import Helmet from "../../components/Helmet";
import bannerCover from "../../assets/defaultBannerAndIcon/banner-placehoder.jpg";

import BannerImage from "../../components/BannerImage";
function TermsConditions({ isPreview, userId, sectionId }) {
  const pageCode = "KTERMCONDITION";
  // const { setIsLoadingMain } = useContext(MyContext);
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );
  const termsImage =
    pagesSectionData1?.data?.sectionData?.filter(
      (item) =>
        item.section_code === "MANAGETC" &&
        item.field_label_code === "BANNERIMAGE"
    )[0]?.psdd_data;

  const pagesSectionData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData(pageCode, isPreview, userId, sectionId)
  );
  const termData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "MANAGETC"
      )[0] ?? ""
  );
 
  const termsContent =
    termData?.[0]?.section_elements.filter(
      (item) => item.field_label_code === "TERMCONDTION"
    )[0]?.data ?? "";
  const termsImageAltTag =
    termData?.[0]?.section_elements.filter(
      (item) => item.field_label_code === "BANNERIMAGE"
    )[0]?.img_alt_tag ?? "";

  const keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
  const slug = pagesSectionData?.data?.data[0]?.slug;
  const meta_title = pagesSectionData?.data?.data[0]?.meta_title;
  const meta_description = pagesSectionData?.data?.data[0]?.meta_description;
 
  // const handleImageLoad = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // const handleError = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // if (pagesSectionData1.isLoading) {
  //   setIsLoadingMain(true);
  //   document.body.classList.add('slider-open');
  //   return <Loader />;
  // }
  // else if (isBannerLoading) {
  //   document.body.classList.add('slider-open');
  //   setIsLoadingMain(true);
  // } 
  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
            {/* <img
              className="w-100 h-100"
              src={termsImage}
              title=""
              alt={termsImageAltTag}
              onLoad={handleImageLoad}
              onError={handleError}
            /> */}
            <BannerImage
              className="w-100 h-100"
              src={termsImage}
              title=""
              alt={termsImageAltTag}
              isDataIsLoading={pagesSectionData1.isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                Terms & Conditions
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>terms & conditions</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <section>
        <div className="terms-condition-wrapper section-padding">
          <div className="container">
            <div className="conditions-inner-wrapper">
              {parse(`${termsContent}`)}
              {/* <p>Only communicates with you on the following emails.</p>
                            <ConditionList listdata={listdata} />
                            <p className='condition-space mb-0'>Our cancellation policy and payment policy are detailed in your official booking voucher, and we do not change it unless in cases of force majeure acknowledged by both sides.</p>
                            <p className='condition-space mb-0'>We do not charge your card details without your signed consent through a credit card authorization form.</p>
                            <p className='condition-space mb-0'>Payment can only be via payment link sent by us, bank transfer or through credit card authorization form.</p>
                            <p className='condition-space mb-0'>Please note that according to local bank regulations, the resort is not in position to accept dollar notes issued prior to 2013 series.</p>
                            <p className='condition-space mb-0'>Kindly note that for added safety we do not request any action from your side or details over telephone without any official communication in writing.</p>
                            <p className='condition-space mb-0'>Should you receive any such communication that are not in compliance with our policy above, we request you to Ignore/report such communications and write to us on your confirmation or one of the emails stated herewith. We request you to not action on such communications should you be in doubt.</p>
                            <p className='condition-space mb-0'>Further to our pre-arrival requirements we are available at your leisure to ensure all arrangements are made smoothly for your upcoming stay, and we are looking forward to having you with us.</p>
                            <h6 className='condition-space mb-0 text-uppercase'>links to third parties</h6>
                            <p className='mb-0'>VARU by Atmosphere does not control any third-party website and is therefore not responsible for the content of any linked website or any link contained in a linked website.</p>
                            <h6 className='condition-space mb-0 text-uppercase'>early check-out</h6>
                            <p className='mb-0'>The property reserves the right to charge an early departure fee in the event a guest departs earlier than the original departure date.</p>
                            <h6 className='condition-space mb-0 text-uppercase'>late check-out</h6>
                            <p className='mb-0'>On request. Please contact the front office of each property. Additional fees may apply.</p> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermsConditions;
