const Loader = () => {
  return (
    <section>
    <div className="logo-fade-animation">
        <div className="logo-overlay-wrapper">
            <div className="logo-wrapper">
                <span className="overlay white-overlay"></span>
            </div>
        </div>
    </div>
</section>
  )
}

export default Loader