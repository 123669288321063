import "./App.css";
import Header from "./components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/scss/responsive.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../node_modules/react-tabs-scrollable/dist/rts.css"
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import AllRoutes from "./allRoutes/AllRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useContext, useState } from "react";
import { MyContext } from "./Contex/LoadingContex";

import UnderMaintenance from "./components/UnderMaintainencePage";
import { setupAxiosInterceptor } from "./services/dataServices";

function App() {
  
  const [errorPage, setErrorPage] = useState(false);
  const { isLoadingMain } = useContext(MyContext);
  const [isServerDown,setIsServerDown]=useState(false);
  setupAxiosInterceptor(setIsServerDown);
  return (
    <>
  
    {/* {isServerDown === true ?<UnderMaintenance/>:''}
    { errorPage === true ? (
        <Header pageNoteFoundBackground={"page-note-found-header"} />
      ) : isLoadingMain ? (
       <Loader/>
      ) : (
        <Header  />
      )}
    <AllRoutes errorPage={errorPage} setErrorPage={setErrorPage} />
   
    {errorPage === true ? (<Footer />):isLoadingMain ? <></> : <Footer />}
    <ToastContainer /> */}
    {isServerDown === true ? (
  <UnderMaintenance setIsServerDown={setIsServerDown} />
) : (
  <>
    {/* Header based on errorPage or loading status */}
    {errorPage === true ? (
      <Header pageNoteFoundBackground={"page-note-found-header"} />
    ) : isLoadingMain ? (
      <Loader />
    ) : (
      <Header />
    )}

    {/* Routes */}
    <AllRoutes errorPage={errorPage} setErrorPage={setErrorPage} />

    {/* Footer based on errorPage or loading status */}
    {errorPage === true ? (
      <Footer />
    ) : isLoadingMain ? <></> : (
      <Footer />
    )}
  </>
)}


<ToastContainer />

  </>
   
  );
}

export default App;
