import React, { useContext, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import PlansTabView from "./PlansTabView";
import { getPageData1, getPageData } from "../../services/dataServices";
import { useQuery } from "react-query";
import bannerCover from "../../assets/defaultBannerAndIcon/banner-placehoder.jpg";
import Helmet from "../../components/Helmet";
import { MyContext } from "../../Contex/LoadingContex";
import Loader from "../../components/Loader";
import BannerImage from "../../components/BannerImage";

function OurPlans({ isPreview, userId, sectionId }) {
  const pageCode = "KPLAN";
  // const { setIsLoadingMain } = useContext(MyContext);
  const [isBannerLoading, setIsBannerLoading] = useState(true);

  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData(pageCode, isPreview, userId, sectionId)
  );
  const pagesSectionData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );


  const bannerImageAltTag =
    pagesSectionData1?.data?.data
      ?.filter((item) => item.page_template_code === "KPLAN")[0]
      .section_details.filter((item) => item.section_code === "MAINBANNER")[0]
      ?.section_elements.filter(
        (item) => item.field_label_code === "BANNERIMAGE"
      )[0]?.img_alt_tag ?? null;

  const bannerImage =
    pagesSectionData?.data?.sectionData?.filter(
      (item) =>
        item.page_template_code === pageCode &&
        item.section_code === "MAINBANNER" &&
        item.field_label_code === "BANNERIMAGE"
    )[0]?.psdd_data;

  const heading = pagesSectionData?.data?.sectionData?.filter(
    (item) =>
      item.page_template_code === pageCode &&
      item.section_code === "BOUNDLESS" &&
      item.field_label_code === "HEADING"
  )[0]?.psdd_data;

  const desc = pagesSectionData?.data?.sectionData?.filter(
    (item) =>
      item.page_template_code === pageCode &&
      item.section_code === "BOUNDLESS" &&
      item.field_label_code === "DESCRIPTION"
  )[0]?.psdd_data;

  const desc2 = pagesSectionData?.data?.sectionData?.filter(
    (item) =>
      item.page_template_code === pageCode &&
      item.section_code === "BOUNDLESS" &&
      item.field_label_code === "DESCRIPTION2"
  )[0]?.psdd_data;

  const PlanLink = pagesSectionData?.data?.sectionData?.filter(
    (item) =>
      item.page_template_code === pageCode &&
      item.section_code === "BOUNDLESS" &&
      item.field_label_code === "PLANLINK"
  )[0]?.psdd_data;

  let keywords = pagesSectionData?.data?.sectionData[0]?.meta_keyword;
  let slug = pagesSectionData?.data?.sectionData[0]?.slug;
  let meta_title = pagesSectionData?.data?.sectionData[0]?.meta_title;
  let meta_description =
    pagesSectionData?.data?.sectionData[0]?.meta_description;

  const pageData = pagesSectionData1?.data?.data
    ?.filter((item) => item.page_template_code === pageCode)[0]
    .section_details.filter((item) => item.section_code === "TABS");

  //   const handleImageLoad = () => {
  //     setIsBannerLoading(false);
  //     setIsLoadingMain(false);
  //   };
  
  //   if (pagesSectionData1.isLoading) {
  //     setIsLoadingMain(true);
  //     return <Loader/>;
  //   }
  //   else if (isBannerLoading) {
  //     setIsLoadingMain(true);
  //   }
  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
            {/* <img
              className="w-100 h-100"
              src={bannerImage}
              title=""
              alt={bannerImageAltTag}
              onLoad={handleImageLoad}
            /> */}
            <BannerImage
              className="w-100 h-100"
              src={bannerImage}
              title=""
              alt={bannerImageAltTag}
              isDataIsLoading={pagesSectionData1.isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                Kanifushi Plan
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Kanifushi Plan</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <section>
        <div className="dining-head-info plan-head-text-view section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="dining-left-side mb-lg-0 mb-4">
                  <div className="dining-name-time pe-xl-5 pe-lg-4">
                    <h3 className="text-uppercase georgia letter-spacing-2 mb-lg-3 pe-xl-4">
                      {heading}
                    </h3>
                  </div>
                  {PlanLink ? (
                    <div className="show-menu mt-lg-5 pt-xl-4">
                      <Link
                        to={PlanLink}
                        target="_blank"
                        className="georgia btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme"
                      >
                        View Kanifushi Plan{" "}
                        <MdArrowOutward className="ms-2 fs-4" />
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* /col-lg-6 */}
              <div className="col-lg-6 mt-lg-0 mt-3">
                <div className="dining-description">
                  <p>{desc}</p>
                  <p className="pt-2">{desc2}</p>
                </div>
              </div>
              {/* /col-lg-6 */}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="experience-tabs-view our-plans-tabs section-padding pt-0">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <PlansTabView pageData={pageData} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurPlans;
