import React, { useContext, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import innerCover from "../../assets/images/offers-cover.jpg";
import { Link } from "react-router-dom";
import OfferCard from "./OfferCard";
import Helmet from "../../components/Helmet";
import { useQuery } from "react-query";
import { getPageData, getPageData1 } from "../../services/dataServices";
import { getOfferData } from "../../services/dataServices";
import BannerImage from "../../components/BannerImage";
function Offers({ isPreview, userId, sectionId ,slugOfOffers}) {
  const pageCode = "KOFFERS";

  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );
  const bannerImage =
    pagesSectionData1?.data?.sectionData?.filter(
      (item) =>
        item.section_code === "OFFERS" &&
        item.field_label_code === "BANNERIMAGE"
    )[0]?.psdd_data;

  const ourSubBrandData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData(pageCode, isPreview, userId, sectionId)
  );
  let AllKanifushiOffers = useQuery("getOfferData", getOfferData);

  const keywords = ourSubBrandData?.data?.data[0]?.meta_keyword;
  const slug = ourSubBrandData?.data?.data[0]?.slug;
  const meta_title = ourSubBrandData?.data?.data[0]?.meta_title;
  const meta_description = ourSubBrandData?.data?.data[0]?.meta_description;

  const bannerImageAltTag =
    ourSubBrandData?.data?.data?.[0]?.section_details
      ?.find((item) => item.section_code === "OFFERS")
      ?.section_elements?.find(
        (item) => item?.field_label_code === "BANNERIMAGE"
      )?.img_alt_tag ?? null;

  // const handleImageLoad = () => {
  //   document.body.classList.remove("slider-open");
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // const handleError = () => {
  //   document.body.classList.remove("slider-open");
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // if (pagesSectionData1.isLoading) {
  //   setIsLoadingMain(true);
  //   document.body.classList.add("slider-open");
  //   return <Loader />;
  // } else if (isBannerLoading) {
  //   document.body.classList.add("slider-open");
  //   setIsLoadingMain(true);
  // }
  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />

      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
            {/* <img
              className="w-100 h-100"
              src={bannerImage}
              title=""
              alt={bannerImageAltTag}
              onLoad={handleImageLoad}
              onError={handleError}
            /> */}
            <BannerImage
              className="w-100 h-100"
              src={bannerImage}
              title=""
              alt={bannerImageAltTag}
              isDataIsLoading={pagesSectionData1.isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                OFFERS
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Our Offers</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>
      <section>
        <div className="sub-brands-tabs offers-tabs section-padding">
          <div className="all-offers-list">
            {AllKanifushiOffers?.data?.data.map((offer) => (
              <OfferCard
                key={offer.website_offer_id}
                offerId={offer.website_offer_id}
                slug={offer.slug}
                offerImage={offer.offer_image}
                offerHeadingOne={offer.offer_name}
                offerSmallHeading={offer.discount_percent}
                offerDicountText={offer.discount_text}
                tabPara={offer.description}
                offerHotelLocation={offer.resort_name}
                offerLink={offer.offerLink}
                bookLink={offer.website_url}
                imgAltTag={offer.offer_image_alt_tag}
                slugOfOffers={slugOfOffers}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Offers;
