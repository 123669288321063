import React, { useState } from 'react'
import Slider from 'react-slick'
import { Button } from "react-bootstrap";
import { AiOutlineArrowsAlt } from "react-icons/ai";
import FullViewVillaSlider from './FullViewVillaSlider';
import Image from './Image';
function VillaSlides({beachSliderLargeSettings, beachSliderThumbSettings,villaImages,villaThumbimages,
  bannerImageAltTa}) {
  const settingsLarge = {...beachSliderLargeSettings}
  const settingsThumb = {...beachSliderThumbSettings}
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [activeCard, setActiveCard] = useState(null);
  const [show, setShow] = useState(false);
  const handleShow = (index) => {
    const clickedImage = villaImages[index];
    const reorderedImages = [clickedImage, ...villaImages.slice(0, index), ...villaImages.slice(index + 1)];
    setActiveCard(reorderedImages);
    setShow(true);
};
  const handleClose = () => {
    setActiveCard(null);
    setShow(false);
  };

  return (
    <>        
      <Slider className='large-slides' {...settingsLarge} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
        
        {villaImages?.map((image,index)=>(
           <div className='position-relative'>
          <div key={index} className='beach-slide-card position-relative'>
            <div className={`card-image overflow-hidden`}>
              {/* <img className='w-100' src={image.image_path} alt={image.property_image_alt_tag|| ''} title='' /> */}

              <Image className="w-100" src={image.image_path} alt={image.property_image_alt_tag|| ''} />
            </div>
            {image.image_path &&
            <div className="full-slide-view alternt-full-view  position-absolute">
                    
                    <Button
                      onClick={() => handleShow(index)}
                      className="btn btn-primary full-btn border-0 smooth text-nowrap"
                    >
                      <AiOutlineArrowsAlt className="me-1" /> {"FULL VIEW"}
                    </Button>
                  </div>}
                    </div>
            
                    </div>
            
        ))}
        
    </Slider>
    <FullViewVillaSlider gallerySlideData={activeCard} show={show} handleClose={handleClose} >

</FullViewVillaSlider>
    <Slider className='thumb-slides slick-arrow-default thumb-slide-default mt-2' {...settingsThumb}  asNavFor={nav1} ref={(slider2) => setNav2(slider2)} swipeToSlide={true} focusOnSelect={true}>        
        {villaThumbimages?.map((thumbimage,index)=>(
          <div key={index} className='beach-slide-card position-relative'>
            <div className={`card-image overflow-hidden`}>
              {/* <img className='w-100' src={thumbimage.image_path} alt={thumbimage.property_image_alt_tag} title='' /> */}
              <Image className="w-100" src={thumbimage.image_path} alt={thumbimage.property_image_alt_tag} />
            </div>
        </div>
        ))}
        
    </Slider>
    </>
  )
}

export default VillaSlides