import React from "react";
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import Image from "../../components/Image";
function ExperienceCard({ ExperienceTabItem }) {
  const ExperienceTabItem1 = ExperienceTabItem.map((item) => ({
    experienceCardHeading:
      item?.filter((field) => field.field_title_code === "ITEMTITLE")[0]
        ?.data ?? null,
    experienceCardImage:
      item?.filter((field) => field.field_title_code === "ITEMIMG")[0]?.data ??
      null,
    experienceCardImageAltTag:
      item?.filter((field) => field.field_title_code === "ITEMIMG")[0]
        ?.img_alt_tag ?? null,
    experienceCardPara:
      item?.filter((field) => field.field_title_code === "ITEMDESC")[0]?.data ??
      null,
    experienceCardPdfLink:
      item?.filter((field) => field.field_title_code === "PRICELISTPDF")[0]
        ?.data ?? null,
  }));

  return (
    <>
      <div className="experience-cards">
        {ExperienceTabItem1.map((item, index) => {
          if (
            item.experienceCardImage === null ||
            item.experienceCardHeading === null
          ) {
            return;
          }

          return (
            <div key={index} className="row align-items-center">
              <div className="col-lg-6">
                <div className="experience-card-img overflow-hidden">
                  {/* <img
                    src={item.experienceCardImage}
                    alt={item.experienceCardImageAltTag}
                    title=""
                  /> */}
                  <Image
                    className="w-100"
                    src={item.experienceCardImage}
                    alt={item.experienceCardImageAltTag}
                    title=""
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="experience-card-text">
                  <h5 className="text-uppercase georgia mb-xl-4 mb-3 letter-spacing-2">
                    {item.experienceCardHeading}
                  </h5>
                  <p>{item.experienceCardPara}</p>
                  <div className="experience-card-action mt-xl-4 mt-3">
                    {item.experienceCardPdfLink ? (
                      <Link
                        target="_blank"
                        className="text-uppercase text-decoration-none fw-bold show-menu smooth"
                        to={item.experienceCardPdfLink}
                      >
                        PRICELIST
                        <MdArrowOutward className="ms-1 fs-5" />
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ExperienceCard;
