import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import GallerySlides from "./GallerySlides";
import { Button } from "react-bootstrap";
import { AiOutlineArrowsAlt } from "react-icons/ai";
import Image from "../../components/Image";
function GalleryView({ galleryCardData }) {
  const [activeId, setActiveId] = useState(0);
  const [counter, setCounter] = useState("");
  const handleUpdateCounter = (updatedCounter) => {
    setCounter(updatedCounter);
  };

  const [shouldSliderTurnTrue, setShouldSliderTurnTrue] = useState(false);

  const onClick = (index) => {
    if (activeId !== index) {
      setShouldSliderTurnTrue(false);
    }

    setActiveId(index);
  };

  useEffect(() => {
    if (activeId !== null) {
      const timerId = setTimeout(() => {
        setShouldSliderTurnTrue(true);
      }, 800);

      return () => clearTimeout(timerId);
    }
  }, [activeId]);

  const gallerySliderSettings = {
    arrows: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
  };
  const [activeCard, setActiveCard] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setActiveCard(null);
    setShow(false);
  };
  const handleTransitionEnd = (e, index) => {};

  return (
    <>
      {galleryCardData &&
        galleryCardData[0]?.subSectionData.map((card, index) => {
          const singleImages =
            (card?.children[0]?.elements?.filter(
              (item) => item.field_title_code === "IMAGE"
            )[0]?.data ??
              null) ||
            [];

          let galleryslidedata = card?.children;

          const galleryIcons =
            card?.elements?.filter(
              (item) => item.field_title_code === "ICON"
            )[0]?.data ?? null;
          const galleryTitle =
            card?.elements?.filter(
              (item) => item.field_title_code === "HEADING"
            )[0]?.data ?? null;
          const galleryDes =
            card?.elements?.filter(
              (item) => item.field_title_code === "DESCRIPTION"
            )[0]?.data ?? null;

          const handleShow = (card) => {
            const clickedImage = galleryslidedata[card];
            const imagesAfterClicked = galleryslidedata.slice(card + 1);
            const imagesBeforeClicked = galleryslidedata.slice(0, card);
            const reorderedImages = [
              clickedImage,
              ...imagesAfterClicked,
              ...imagesBeforeClicked,
            ];
            setActiveCard(reorderedImages);
            setShow(true);
          };

          return (
            <div
              key={index}
              className={`gallery-item smooth position-relative d-flex align-items-end overflow-hidden ${
                activeId === index ? "active" : ""
              }`}
              onClick={() => onClick(index)}
              style={{ backgroundImage: `url(${singleImages})` }}
         
              onTransitionEnd={(e) => handleTransitionEnd(e, index)}
            >
              <div className="gallery-cover-bg smooth">
                {/* <Image src={singleImages} alt=""/> */}
              </div>
              <div className="gallery-slider smooth h-100 w-100">
                {shouldSliderTurnTrue ? (
                  <GallerySlides
                    gallerySliderSettings={gallerySliderSettings}
                    galleryslidedata={galleryslidedata}
                    counter={counter}
                    onUpdate={handleUpdateCounter}
                    callfrom={"slider"}
                  />
                ) : (
                  ""
                )}
              </div>

              {card.elements.map((tagdata, index) => {
                return (
                  <div
                    className="gallery-card-caption position-absolute smooth z-1"
                    key={index}
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="gallery-card-icon bg-white rounded-circle overflow-hidden d-flex justify-content-center align-items-center">
                        {/* <img src={galleryIcons} alt="" title="" /> */}

                        <img src={galleryIcons} alt="" title="" />
                      </div>
                      <div className="gallery-card-text smoth text-nowrap">
                        <h6 className="text-white mb-0 fw-bold smoth">
                          {galleryTitle}
                        </h6>
                        <span className="text-white smoth">{galleryDes}</span>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="full-slide-view position-absolute">
                <Button
                  onClick={() => handleShow(counter)}
                  className="btn btn-primary full-btn border-0 smooth text-nowrap"
                >
                  <AiOutlineArrowsAlt className="me-1" /> {"FULL VIEW"}
                </Button>
              </div>
            </div>
          );
        })}

      <Modal
        className="gallery-slide-modal"
        size="xl"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        {activeCard && (
          <>
            {/* Pass the relevant data to GallerySlides */}
            <GallerySlides
              gallerySliderSettings={gallerySliderSettings}
              galleryslidedata={activeCard}
            />
            {/* Other modal content using activeCard */}
          </>
        )}
      </Modal>
    </>
  );
}

export default GalleryView;
