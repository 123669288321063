import React from "react";
import VillasTabItem from "./VillasTabItem";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";

import userOne from "../assets/images/user.svg";
import bedOne from "../assets/images/bed.svg";
import areOne from "../assets/images/area.svg";
import { getAllVillas } from "../services/dataServices";
import Scrollbars from "react-custom-scrollbars";

import { useQuery } from "react-query";
const ExploreVillasTabs = ({villaSlug}) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

  const AllVillas = useQuery("villalist", getAllVillas);
 
  const truncateText = (text = "", length) => {
    return text?.length > +length ? text.slice(0, +length) + "..." : text;
  };

  function generateOccupancyText(property_occupancy) {
    let textArray = [];

    for (let i = 0; i < property_occupancy.length; i++) {
        let occu = property_occupancy[i];
        let adultsText = '';
        let childrenText = '';

        // Handling adults count text
        if (occu.adult_count === 1) {
            adultsText = `${occu.adult_count} adult`;
        } else {
            adultsText = `${occu.adult_count} adults`;
        }

        // Handling children count text
        if (occu.child_count !== 0) {
            if (occu.child_count === 1) {
                childrenText = `${occu.child_count} child`;
            } else {
                childrenText = `${occu.child_count} children`;
            }
            textArray.push(`${adultsText} + ${childrenText}`);
        } else {
            textArray.push(adultsText); // If there are no children, only add adults count
        }

        if (i !== property_occupancy.length - 1) {
            textArray[textArray.length - 1] += ' or ';
        }
    }

    return textArray;
}


  return (
    <>
      <Scrollbars className="home-villa-scrollbar" style={{height: 695}}>
          <Tabs
            activeTab={activeTab}
            onTabClick={onTabClick}
            AllVillas={AllVillas?.data?.data}
          >
            {/* generating an array to loop through it  */}

              {AllVillas &&
                AllVillas?.data?.data?.map((item, index) => (
                  <Tab
                    key={index}
                    className={`item ${activeTab === index
                        ? "active text-uppercase d-flex align-items-center"
                        : "text-uppercase d-flex align-items-center"
                      }`}
                  >
                    <span className="tab-villa-icon"></span>
                    <span className="tab-name">{item.property_name}</span>
                  </Tab>
                ))}
          </Tabs>
      </Scrollbars>
      {AllVillas &&
        AllVillas?.data?.data?.map((screen, index) => {
          // const occupancy = screen.property_occupancy.map((occu)=>
          // {return(` ${occu.adult_count} adults + ${occu.child_count} children`)}
          // )
        
          const occupancy = generateOccupancyText(screen.property_occupancy);

          const adultCount = [];
          const villaUserIcon = userOne;
          const villaBedIcon = bedOne;
          const villaAreaIcon = areOne;
          const detailText = "View Details";
          const viewDetailsLink = "/kanifushi-residence";
          const bookNowLink = "2";
          const bookNowText = "BOOK NOW";
          // const totalVillaAreaIcon = totalVillaIConView;
          const villaArea = `Total Area - ${screen.area_sqm}m²`;
          // const privatePoolIcon = privatePoolimg;
          const showPrivatePool = false;
          const showVillaArea = false;
          const imgUrl = screen.property_images.map((img) => ({
            imageUrl: img.image_path,
            imageAltTag: img.property_image_alt_tag,
          }));
          const thumbImageUrl = screen.property_images.map((img) => ({
            thumbImageUrl: img.image_path,
            imageAltTag: img.property_image_alt_tag,
          }));
          const bedTypeData = screen.property_beds.map((bed, index) =>
            index > 0 ? `, ${bed.bed_count} ${bed.bed_count === 1 ? bed.bed_type : bed.bed_type + 's'}` : `${bed.bed_count} ${bed.bed_count === 1 ? bed.bed_type : bed.bed_type + 's'}`
          );
          let paraDescription = "";
          if (screen.property_description) {
            paraDescription = truncateText(screen.property_description, 100)
          }


          return (
            <TabScreen
              key={index}
              activeTab={activeTab}
              index={index}

            // You can add animation with adding a custom class
            >
              <VillasTabItem
                villaName={screen.property_name}
                villaSlogan={screen.tag_line}
                villaPara={paraDescription}
                adultCount={occupancy.join('\n')}
                villaBed={bedTypeData}
                villaUserIcon={villaUserIcon}
                villaBedIcon={villaBedIcon}
                villaArea={villaArea}
                villaAreaIcon={villaAreaIcon}
                viewDetailsLink={`/${villaSlug}/${screen.slug}`}
                detailText={detailText}
                bookNowLink={bookNowLink}
                bookNowText={bookNowText}
                // slideVillaData={screen.slideVillaData}
                items={screen.items}
                image={screen.image}
                thumbimages={thumbImageUrl}
                images={imgUrl}
              />
            </TabScreen>
          );
        })}
    </>
  );
};

export default ExploreVillasTabs;
