import React from "react";
import { useQuery } from "react-query";
import { getAllVillas } from "../../services/dataServices";
import VillasList from "./VillasList";
import userOne from "../../assets/images/user.svg";
import bedOne from "../../assets/images/bed.svg";
import areOne from "../../assets/images/area.svg";
import totalVillaIConView from "../../assets/images/villa-area.svg";

import privatePoolimg from "../../assets/images/swimming-pool.svg";

const AllVillas = ({slugOfOurVillas}) => {
  const AllVillas = useQuery("villalist", getAllVillas);

  const truncateText = (text = "", length) => {
    return text?.length > +length ? text.slice(0, +length) + "..." : text;
  };

  function generateOccupancyText(property_occupancy) {
    let textArray = [];

    for (let i = 0; i < property_occupancy.length; i++) {
      let occu = property_occupancy[i];
      let adultsText = "";
      let childrenText = "";

      // Handling adults count text
      if (occu.adult_count === 1) {
        adultsText = `${occu.adult_count} adult`;
      } else {
        adultsText = `${occu.adult_count} adults`;
      }

      // Handling children count text
      if (occu.child_count !== 0) {
        if (occu.child_count === 1) {
          childrenText = `${occu.child_count} child`;
        } else {
          childrenText = `${occu.child_count} children`;
        }
        textArray.push(`${adultsText} + ${childrenText}`);
      } else {
        textArray.push(adultsText); // If there are no children, only add adults count
      }

      if (i !== property_occupancy.length - 1) {
        textArray[textArray.length - 1] += " or ";
      }
    }

    return textArray;
  }

  return (
    <section>
      <div className="explore-kanifushi-villas villas-list-page section-padding ps-0 pe-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {AllVillas?.data?.data?.map((data) => {
                // Calculate adult and child counts
                // const adultCount = ` ${data.occupancy1_adult} adults + ${data.occupancy1_child} children or ${data.occupancy2_adult} adults + ${data.occupancy2_child} children`;
                const occupancy = generateOccupancyText(
                  data.property_occupancy
                );
                const villaUserIcon = userOne;
                const villaBedIcon = bedOne;
                const villaAreaIcon = areOne;
                const detailText = "View Details";
                const viewDetailsLink = `/${slugOfOurVillas}/${data.slug}`;
                const bookNowLink = "2";
                const bookNowText = "BOOK NOW";
                const totalVillaAreaIcon = totalVillaIConView;
                const villaArea = `Total Area - ${data.area_sqm}`;
                const privatePoolIcon = privatePoolimg;
                const showPrivatePool = false;
                const showVillaArea = false;
                const tagline = data?.tag_line;
                return (
                  <VillasList
                    key={data.property_id}
                    villaName={data.property_name}
                    villaSlogan={tagline}
                    villaPara={truncateText(data.property_description, 87)}
                    adultCount={occupancy.join("\n")}
                    villaBed={data.property_beds.map((bed) => bed)}
                    villaUserIcon={villaUserIcon}
                    villaBedIcon={villaBedIcon}
                    villaArea={villaArea}
                    villaAreaIcon={villaAreaIcon}
                    viewDetailsLink={viewDetailsLink}
                    detailText={detailText}
                    bookNowLink={bookNowLink}
                    bookNowText={bookNowText}
                    slideVillaData={data.slideVillaData}
                    items={data.items}
                    villaImages={data.property_images}
                    villaThumbimages={data.property_images}
                    totalVillaAreaIcon={totalVillaAreaIcon}
                    totalVillaArea={villaArea}
                    privatePoolIcon={privatePoolIcon}
                    privatePoolArea={data.private_pool_size}
                    showVillaArea={showVillaArea}
                    showPrivatePool={showPrivatePool}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllVillas;
