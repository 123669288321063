import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import Helmet from "../components/Helmet";
import Image from "../components/Image";
function PressReleaseCard({
  cardImage,
  cardTitle,
  cardDate,
  cardDescription,
  cardLink,
  cardUrl,
  keywords,
  slug,
  metaDescription,
  title,
  imgAltTag,
}) {

 
  return (
    // <Helmet/>
    <div className="card border-0">
      <div className="card-image">
        {/* <img src={cardImage} alt={imgAltTag} title="" /> */}
        <Image src={cardImage} alt={imgAltTag} title="" />
      </div>
      <div className="card-body">
        <h4 className="georgia mb-0">{cardTitle}</h4>
        <div className="press-date my-lg-4 my-3">
          <span>{cardDate}</span>
        </div>
        <p className="card-text">{cardDescription}</p>
        <div className="read-more text-end">
          {/* <Link className="text-decoration-none text-uppercase" to={cardUrl}> */}
          <Link className="text-decoration-none text-uppercase" to={slug}>
            {cardLink} <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PressReleaseCard;
